<template>
  <div class="container">
    <div class="alert alert-dismissible alert-info p-3 mt-2">
      Notice: While creating a reservation, ensure your payment is processed
      only one time. Please review the reservations on your customer profile to
      verify you are not creating duplicate transactions.
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <h3 class="my-3">Shopping Cart</h3>
      </div>
      <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
        <button
          v-if="cartData.items.length > 0"
          class="btn btn-primary mt-3 pay-now float-right"
          type="button"
          @click="continueShopping"
          :disabled="submittingPayment"
        >
          CONTINUE SHOPPING
        </button>
      </div>
      <div class="col-md-4 col-sm-12"></div>
    </div>
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <Cart
          :cart="cartData"
          :tenantId="tenantId"
          @itemRemoved="itemRemoved"
          :isAdmin="false"
        />
      </div>
      <div class="col-md-4 col-sm-12">
        <OrderSummary
          :cart="cartData"
          :tenantId="tenantId"
          @checkout="payNow"
          @getConvenienceFee="setConvenienceFee"
          :submittingPayment="submittingPayment"
          :admin="false"
        />
      </div>
    </div>
    <div class="row my-5 my-md-5" v-if="cartData.items.length > 0">
      <div class="col-md-8 col-sm-12">
        <button
          class="btn btn-primary mt-n5 pay-now float-right"
          type="button"
          @click="continueShopping"
          :disabled="submittingPayment"
        >
          CONTINUE SHOPPING
        </button>
      </div>
      <div class="col-md-4 col-sm-12"></div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/CartService.js";
import Cart from "@/components/cart/Cart.vue";
import OrderSummary from "@/components/cart/OrderSummary.vue";

export default {
  name: "ShoppingCart",
  title: "Shopping Cart",
  components: {
    Cart,
    OrderSummary
  },
  data() {
    return {
      cartData: {},
      loading: false,
      convenienceFee: {},
      submittingPayment: false
    };
  },
  beforeCreate() {
    window.scrollTo(0, 0);
  },
  methods: {
    loadCart() {
      this.$store.commit("auth/setLoading", true);
      const cartService = new CartService(this.tenantId);
      cartService
        .getUserCart()
        .then(response => {
          if (!response) {
            this.cartData = { items: [] };
          } else {
            this.cartData = response;
          }
        })
        .finally(() => {
          this.$store.commit("auth/setLoading", false);
        });
    },
    continueShopping() {
      this.$router.push("/").catch(() => {});
    },
    async payNow(paymentTypeId) {
      this.submittingPayment = true;
      const cartService = new CartService(this.tenantId);
      const response = await cartService.checkout(paymentTypeId);
      if (response && response.statusCode === "Success") {
        if (response.ccpRedirectUrl) {
          window.location = response.ccpRedirectUrl;
        } else {
          this.$router.push("/reservation-receipt?orderId=" + response.orderId);
        }
      } else if (response && response.statusCode === "Failure") {
        let errorMessage = "Error during checkout.";
        if (response.messages && response.messages.length > 0) {
          errorMessage = response.messages[0];
        }
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: errorMessage,
          layer: "public"
        });
        this.submittingPayment = false;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
        this.submittingPayment = false;
      }
    },
    itemRemoved() {
      this.loadCart();
    },
    setConvenienceFee(fee) {
      this.convenienceFee = { ...fee };
    }
  },
  mounted() {
    this.loadCart();
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  }
};
</script>

<style scoped>
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.alert-header {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  font-size: 1.3125rem;
}
.fine-print {
  font-size: smaller;
}
</style>
